<template>
  <div id="motor">
    <div class="container mt-5">
      <div class="row">
        <div class="col-12 text-center">
          <h1 class="title">Fast & Easy Car Takaful Renewal</h1>
          <div class="subtitle">Enjoy coverage and protection benefits</div>
        </div>
        <div class="row mt-4">
          <div class="col-12">
            <img class="img-fluid banner-img" src="../assets/images/motors/banner.jpg">
          </div>
        </div>
        <div class="row mt-5 mx-auto">
          <div class="col-12 col-md-4 mb-5">
            <div class="row">
              <div class="col-2 icon-col">
                <i class="fa fa-check" aria-hidden="true"></i>
              </div>
              <div class="col-10 desc-col">
                10% instant rebate upon renewal
              </div>
            </div>
          </div>
          <div class="col-12 col-md-4 mb-5">
            <div class="row">
              <div class="col-2 icon-col">
                <i class="fa fa-check" aria-hidden="true"></i>
              </div>
              <div class="col-10 desc-col">
                Free 200km car-towing assistance*
              </div>
            </div>
          </div>
          <div class="col-12 col-md-4 mb-5">
            <div class="row">
              <div class="col-2 icon-col">
                <i class="fa fa-check" aria-hidden="true"></i>
              </div>
              <div class="col-10 desc-col">
                Cashback for no claims
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-5 col-xl-5 mb-5">
            <div class="row">
              <div class="col-2 col-md-1 col-lg-2 col-xl-2 icon-col">
                <i class="fa fa-check" aria-hidden="true"></i>
              </div>
              <div class="col-10 col-md-11 col-lg-10 col-xl-10 desc-col">
                0% interest instalment plans for Maybank credit cards
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-5 col-xl-5 mb-5">
            <div class="row">
              <div class="col-2 col-md-1 col-lg-2 col-xl-2 icon-col">
                <i class="fa fa-check" aria-hidden="true"></i>
              </div>
              <div class="col-10 col-md-11 col-lg-10 col-xl-10 desc-col">
                Access to the Etiqa Smile app  which is equipped with GPS Locator services
              </div>
            </div>
          </div>
          <div class="col-2 col-xl-2 d-md-none d-lg-block d-xl-block"></div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 mb-5">
          <div class="card shadow">
           <div class="card-body">
             <div class="row text-center mb-4">
               <div class="col-12">
                 <h2 class="title">Let's Find Your Vehicle</h2>
               </div>
             </div>

             <div class="row">
               <div class="col-12">
                 <p>Vehicle Registration Number (Plate Number)</p>
                 <input class="form-control form-control-lg is-invalid" id="invalidPlate" aria-describedby="invalidPlateFeedback" v-model="plate_number" type="text" placeholder="e.g. CDX9191" required>
                 <div id="invalidPlate" class="invalid-feedback">
                   Please enter your vehicle registration number
                 </div>
               </div>

               <div class="col-12 mt-4">
                 <button type="button" class="btn btn-outline-primary btn-lg find-car-btn" v-on:click="submitPlateNumber()" v-if="!is_vehicle_loading">Find My Vehicle</button>
                 <div class="spinner-border" role="status" v-if="is_vehicle_loading">
                   <span class="sr-only">Loading...</span>
                 </div>
               </div>
             </div>
           </div>
          </div>
        </div>
        <div class="col-12 col-md-12 col-lg-6 col-xl-6" v-if="vehicle">
          <div class="card shadow">
            <div class="card-body">
              <div class="row">
                <div class="col-3 col-md-2 my-auto">
                  <i class="fa fa-car car-icon" aria-hidden="true"></i>
                </div>
                <div class="col-9 col-md-10 car-title-col my-auto">
                  <span class="name">{{ vehicle.nvic.make }} {{ vehicle.nvic.model }} {{ vehicle.nvic.variant }} <br> {{ vehicle.nvic.transmission }}</span><br>
                  <span class="cc">{{ vehicle.nvic.cc }} cc</span><br>
                  <span class="year">year {{ vehicle.nvic.year }}</span>
                </div>
              </div>
              <hr>
              <div class="row mt-4">
                <div class="col-12 text-center text-md-left">
                  <h4>Let's get a quote</h4>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-12">
                  <p>Postcode (Vehicle Location)</p>
                  <input class="form-control form-control-lg is-invalid" v-model="postcode" id="postcode" aria-describedby="invalidPostcode" type="text" placeholder="e.g. 01000" required>
                  <div id="invalidPostcode" class="invalid-feedback">
                    Please enter your postcode
                  </div>
                </div>
              </div>
              <div class="row mt-3 text-center text-md-left">
                <div class="col-12">
                  <p>ID Type</p>
                  <div class="btn-group btn-group-toggle" data-toggle="buttons">
                    <label class="btn btn-primary" :class="{active: is_nric}">
                      <input type="radio" name="id_type" checked v-on:click="changeIDType('nric')"> NRIC
                    </label>
                    <label class="btn btn-primary" :class="{active: !is_nric}">
                      <input type="radio" name="id_type" v-on:click="changeIDType('passport')"> Passport
                    </label>
                  </div>
                </div>
                <div class="col-12 mt-4 nric_cont" v-if="is_nric">
                  <p>NRIC Number</p>
                  <input class="form-control is-invalid" id="ic_number" v-model="ic_number" aria-describedby="invalidIcNumber" type="text" placeholder="e.g. 910203040568" required>
                  <div id="invalidIcNumber" class="invalid-feedback">
                    Please enter your IC Number
                  </div>
                </div>

                <div class="col-12 mt-4 passport_cont" v-if="!is_nric">
                  <p>Passport Number</p>
                  <input class="form-control is-invalid" id="passport" v-model="passport_number" aria-describedby="invalidPassport" type="text" placeholder="e.g. VD1289281" required>
                  <div id="invalidPassport" class="invalid-feedback">
                    Please enter your Passport Number
                  </div>

                  <p class="mt-4">Nationality</p>
                  <select class="custom-select nationality">
                    <option selected>Select Nationality</option>
                    <option v-for="country in countries" :key="country.id">{{ country.name }}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-12 col-lg-6 col-xl-6 my-5 my-md-5 my-lg-auto my-xl-auto text-center" v-if="quotationValidCheck">
          <div class="row">
            <div class="col-12 mb-4">
              <span class="icon-span"><i class="fa fa-thumbs-up" aria-hidden="true"></i></span>
            </div>
            <div class="col-12">
              <p style="color: #519872; font-weight: 600">You are good to go!</p>
            </div>
            <div class="col-12">
              <button type="button" class="btn btn-primary btn-lg find-car-btn" v-on:click="submitGetQuote">Get My Quote</button>
            </div>
          </div>
        </div>
      </div>
      <div class="space"></div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import router from "@/router";
export default {
  name: "Motor",
  data() {
    return {
      countries: [],
      is_nric: true,
      plate_number: '',
      vehicle: '',
      ic_number: '',
      postcode: '',
      passport_number: '',

      is_vehicle_loading: false,
      is_quote_loading: false
    }
  },
  computed: {
    quotationValidCheck () {

      if (this.ic_number && this.postcode)
      {
        return true;
      }

      else {
        return false;
      }
    }
  },
  mounted() {

    axios.get('/.netlify/functions/sign-tokensignature')
        .then(response => {
          console.log(response.data)
        })
  },
  methods: {
    changeIDType(type) {

      //NRIC input
      if (type === 'nric')
      {
        this.is_nric = true;
      }
      //Passport input
      else {
        this.is_nric = false;

        axios.get('https://dev-dnm.nedex.io/api/countries/get')
            .then((res) => {
              this.countries = res.data.countries;
            })
            .finally(() => {

            })
      }
    },
    submitPlateNumber() {
      this.is_vehicle_loading = true;

      axios.get('/.netlify/functions/sign-tokensignature')
          .then(response => {
            localStorage.timestamp = response.data.timestamp;
            localStorage.signature = response.data.signres;
            this.getPlateNumberDetail()
          })
    },
    getPlateNumberDetail() {

      axios.post(this.$hostname+'/api/etiqa/vehicle/info', {
        plate_number: this.plate_number,
        timestamp: localStorage.timestamp,
        signature: localStorage.signature
      })
          .then((response) => {
            if (response.data.vehicle.status === 'VALIDATION_SUCCESS') {
              this.vehicle = response.data.vehicle;
              console.log(this.vehicle);
            }

            this.is_vehicle_loading = false;
          })
          .catch((error) => {
            console.log(error);
          });

    },
    submitGetQuote() {
      this.is_quote_loading = true;

      axios.post('http://dnm-laravel.test/api/get/quote', {
        plate_number: this.plate_number,
        ic_number: this.ic_number,
        postcode: this.postcode
      })
          .then((response) => {
            console.log (response.data);
            this.is_quote_loading = false;

            if (response.data.quote.status === 'VALIDATION_SUCCESS') {
              sessionStorage.setItem('quoteData', JSON.stringify(response.data.quote))
              router.push({ path: '/motor/quote'})
            }
          })
          .catch((error) => {
            console.log(error);
          });
    }
  }
}
</script>

<style scoped>

</style>
