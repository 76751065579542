<template>
  <div class="home">
    <div class="row">
      <div class="col-12 sticky-col">
        <div class="row">
          <div class="col-12">
            <div class="btn-group-vertical btn-group-sticky">
              <router-link href="#" to="/quote" class="shadow btn btn-default sticky-quote-btn">Get Quote</router-link>
              <a :href="Brochure" class="shadow btn btn-primary sticky-brochure-btn">Get Brochure</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col s12 text-center my-5">
          <h2 class="title mb-5 mb-sm-3">Etiqa's Medical Professional Indemnity Takaful (MedPro)</h2>
          <p class="subtitle">Etiqa's Medical Professional Indemnity Takaful (MedPro) coverage provides comprehensive protection for Medical practitioners, so that they are enabled to provide the best care or lifesaving treatment for their patients. The benefit highlights include:</p>
        </div>
      </div>
      <div class="row benefit-row">
        <div class="col-md-4 col-12 text-center">
          <img class="img-fluid" src="../assets/images/logos/Libel-or-Slander.png">
          <p>Coverage for unintentional libel or slander with no malice.</p>
        </div>
        <div class="col-md-4 col-12 text-center">
          <img class="img-fluid" src="../assets/images/logos/Consultation.png">
          <p>2 hours of complimentary legal advisory services.</p>
        </div>
        <div class="col-md-4 col-12 text-center">
          <img class="img-fluid" src="../assets/images/logos/Court-Attendance.png">
          <p>Compensation of up to RM10,000 per claim for Court Attendance.</p>
        </div>

        <div class="col-md-4 col-12 text-center">
          <img class="img-fluid" src="../assets/images/logos/Expenses-for-Public-Relations.png">
          <p>Coverage for expenses to re-establish participant's reputation, due to adverse publicity.</p>
        </div>
        <div class="col-md-4 col-12 text-center">
          <img class="img-fluid" src="../assets/images/logos/Good-Samaritan-Acts.png">
          <p>Coverage on claims resulting form rendering or failure to render medical assistance in an emergency situation.</p>
        </div>
        <div class="col-md-4 col-12 text-center">
          <img class="img-fluid" src="../assets/images/logos/No-Claims-Discount.png">
          <p>Get 5% discount on the contribution for the following year, for every claim-free year.</p>
        </div>
      </div>
      <div class="row">
        <div class="col-12 mt-5 text-center">
          <p class="notes">Subject to policy terms and conditions.</p>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-12 mt-5">
          <div class="row">
            <div class="col-md-12 col-12 mb-4">
              <img class="img-fluid" src="../assets/images/quote-banner-v2.png">
            </div>
            <div class="col-md-12 col-12 text-center">
              <h2 class="title mb-5 mb-sm-3">A Plan for Medical Practitioners</h2>
              <p>MedicalPro Indemnity Takaful is a holistic indemnity takaful Scheme which covers individual medical practitioners from all specialties against actual or alleged claims of negligence in Malaysia. The Scheme is tailor-made to meet the long term and evolving needs of medical practitioners with access to some of the best medico-legal advisors available. A dedicated team manages the Scheme to ensure that all claims and notifications are handled competently and professionally. DNM Advisory has 15 years experience in managing professional indemnity takaful schemes in Malaysia, who constantly strives to protect the interest and the welfare of our clients</p>
              <router-link href="#" to="/quote" class="shadow px-5 mt-3 btn quote-btn btn-lg">Get Quote</router-link>
              <a :href="Brochure" target="_blank" type="button" class="btn btn-link px-5 mt-3 brochure-btn btn-lg">Get Brochure</a>
              <p class="mt-4 notes">*Please ensure you have a copy of your Current Practicing Certificate and the Full Registration Certificate before you proceed with Get Quote</p>
            </div>
          </div>
        </div>
      </div>
      <hr>
    </div>
    <div class="space"></div>
    <div class="container">
      <div class="row mb-5">
        <div class="col-12">
            <div class="card etiqa-card shadow-lg">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-4 col-12 my-auto text-center text-md-left">
                    <img class="img-fluid" src="../assets/images/logo/EGTB.png">
                  </div>
                  <div class="col-md-8 col-12">
                    <h2 class="mb-5 mt-5 mt-sm-0 title">Our Takaful Provider</h2>
                    <p>Underwritten by Etiqa General Takaful Berhad (Co Reg. No.: 201701025031) (Licensed under the Islamic Financial Services Act 2013 and regulated by Bank Negara Malaysia)</p>
                    <p> For more information, please visit <a href="https://wwww.etiqa.com.my" target="_blank">https://wwww.etiqa.com.my</a></p>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
      <hr>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-12 text-center my-5">
          <h2 class="title">Certificate Details</h2>
        </div>
        <div class="col-12">
          <p><b>* Medium Risk</b> : Oral and Maxillo-Facial Surgery, Orthodontics, Implants, Bone-Harvesting, Facial Aesthetics and any procedure that requires general anaesthesia.</p>
          <p><b>* Government doctors</b> doing private locum work must be in accordance with the terms and conditions set out in the Circular by the Managing Director of Malaysian Ministry of Health's Bil. 2/2010 - Guidelines on the Enforcement Locum Work In the Private Sector by Medical Officers of the Malaysian Ministry of Health dated 12.3.2010 and any revisions thereto.</p>
          <p>* Additional Contribution for <b>Automatic Reinstatement</b> (Double Aggregate Limit) = 10% of the above subscription rate.</p>
          <p>* Please note the above rates are only applicable to applicants without any claims or notifiable circumstances in the last five years. Those with claims or notifiable circumstances <b>will be subjects to individual underwriting.</b></p>
          <p><b>* Pro rata rates</b> available for renewal / application with minimum 6 months or maximum 18 months cover.</p>
        </div>
      </div>
    </div>
    <div class="space"></div>
    <!--<div class="container">
      <div class="row">
        <div class="col-md-6 col-12 text-center card-col" :style="{ backgroundImage: `url(${BgImage})` }" style="background-size: cover">
          <h3 class="title mb-5">Get your car protection with MotorTakaful</h3>
          <a href="#" class="btn btn-primary get-a-quote-btn btn-lg px-4">Get a quote</a>
        </div>
        <div class="col-md-6 col-12 text-center card-col" :style="{ backgroundImage: `url(${BgImage2})` }" style="background-size: cover">
          <h3 class="title mb-5">Get your car protection with MotorTakaful</h3>
          <a href="#" class="btn btn-primary get-a-quote-btn btn-lg px-4">Get a quote</a>
        </div>
      </div>
    </div>
    <div class="space"></div>-->
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import BgImage from '../assets/images/image_11.png';
import BgImage2 from '../assets/images/image_12.png';
import Brochure from '../assets/images/MedIndem_FAOL.pdf';
import { WOW } from 'wowjs';

export default {
  name: "Home",
  /*components: {
    HelloWorld
  }*/
  data () {
    return {
      BgImage,
      BgImage2,
      Brochure
    }
  },
  mounted() {
    const wow = new WOW();

    wow.init();
  }
};
</script>
