<template>
  <div id="app">
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <a class="navbar-brand" href="https://www.dnmadvisory.com">
        <img class="img-fluid" src="./assets/images/logo/dnmadvisory-weblogo2.png">
      </a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="nav navbar-nav ml-auto">
          <li class="nav-item active">
            <a class="nav-link" href="https://www.dnmadvisory.com#whoweare">Who We Are <span class="sr-only">(current)</span></a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="https://www.dnmadvisory.com#whatwedo">What We Do</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="https://www.dnmadvisory.com#knowledge">Knowledge</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="https://www.dnmadvisory.com#clientcenter">Client Center</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="https://www.dnmadvisory.com##contactus">Contact Us</a>
          </li>
        </ul>
      </div>
    </nav>
    <router-view />
    <footer>

    </footer>
  </div>
</template>

<script>
  export default {
    mounted () {
      // this.authenticate()
    },
    methods: {
      async authenticate () {
        window.axios.post('/api/oauth-grant')
          .then((res) => {
            console.log(res)
          })
      }
    }
  }

</script>

<style lang="scss">
/*#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}*/
</style>
