<template>
  <div id="quote">
    <div class="container mt-5">
      <div class="row">
        <div class="col-12 col-md-12 col-xl-6 col-lg-6 order-1 order-md-1 order-xl-0 order-lg-0  mt-4 mt-md-4 mt-xl-0 mt-lg-0">
          <div class="row">
            <div class="col-12">
              <div class="card shadow-sm py-3" style="background-color: #FCFAF9">
                <div class="card-body">
                  <div class="row">
                    <div class="col-12">
                      <div class="indicate-title mb-3">Sum Covered</div>
                      <p>Your current vehicles' market value as of <b>{{ moment().format('D/MM/YYYY') }}</b> is RM{{ quote.sumInsured }}</p>
                    </div>

                    <div class="col-12">
                      <p style="font-weight: 600">Please select your preferred sum covered type</p>
                      <div class="row">

                        <div class="col-6 text-center" v-if="quote.marketValueRange">
                          <div class="card shadow-sm">
                            <div class="card-body">
                              <div class="value-title">Market Value</div>
                              <div class="font-weight-lighter">RM{{ quote.marketMinimum | toDecimal }} - RM{{ quote.marketMaximum | toDecimal }}</div>
                            </div>
                          </div>
                        </div>

                        <div class="col-6 text-center" v-if="quote.agreedValueRange">
                          <div class="card shadow-sm">
                            <div class="card-body">
                              <div class="value-title">Agreed Value</div>
                              <div class="font-weight-lighter">RM{{ agreedMin }} - RM{{ agreedMax }}</div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row mt-4">
                        <div class="col-12">
                          <div class="subtitle">Market Value</div>
                          <p>We will reimburse you with the value of your car at the time of total loss or theft. <span style="font-style: italic">*The amount stated is the maximum amount.</span></p>
                        </div>
                        <div class="col-12">
                          <div class="subtitle" v-if="quote.agreedValueRange">Agreed Value</div>
                          <p>We will reimburse you with the total amount in case of total loss or theft.</p>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-12">
                          <div class="subtitle mb-2">Preferred Sum Covered</div>
                          <div class="input-group">
                            <span class="input-group-text" id="basic-addon1">RM</span>
                            <input type="text" class="form-control" placeholder="18,000" aria-describedby="basic-addon1" v-bind:value="sum_covered_range | toDecimal">
                          </div>
                          <input v-model.lazy="sum_covered_range" type="range" class="custom-range mt-3" step="1000" :min="quote.marketMinimum | toNumber" :max="quote.marketMaximum | toNumber" id="customRange3">
                          <div class="row">
                            <div class="col-6 text-left">
                              <p>RM{{ quote.marketMinimum | toNumber  }}</p>
                            </div>
                            <div class="col-6 text-right">
                              <p>RM{{ quote.marketMaximum | toNumber  }}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div class="row mt-5">
            <div class="col-12">
              <div class="indicate-title">Confirm your details</div>
              <p class="font-weight-normal">Please fill in your correct details</p>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <div id="accordion" class="detail-cards">
                <div class="card shadow py-4 mb-4">
                  <div class="card-header" id="headingOne">
                    <div class="row">
                      <div class="col-2 my-auto">
                        <i class="fa fa-car card-icon" aria-hidden="true"></i>
                      </div>
                      <div class="col-8">
                        <div class="card-detail-title">Car Details</div>
                        <a href="#" class="edit-link">Edit</a>
                      </div>
                      <div class="col-2 my-auto">
                        <i class="fa fa-angle-down arrow-icon" v-bind:class="{ rotate: is_car_icon_rotate }" v-on:click="rotate('car')" aria-hidden="true" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"></i>
                      </div>
                    </div>
                    <!--<h5 class="mb-0">
                      <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        Collapsible Group Item #1
                      </button>
                    </h5>-->
                    <div class="row mt-3">
                      <div class="col-12">
                        <div class="card-detail-subtitle">Plate Number</div>
                        <div class="card-detail-desc font-weight-normal-bold">PKE8080</div>
                      </div>
                    </div>
                  </div>
                  <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-12">
                          <div class="card-detail-subtitle">Make, Model & Variant</div>
                          <div class="card-detail-desc font-weight-normal-bold">FORD FIESTA SPORT Ti-VCT-6 SP AUTO SPORTS MODE-1596 2011</div>
                        </div>
                      </div>

                      <div class="row mt-3">
                        <div class="col-12">
                          <div class="card-detail-subtitle">Chassis Number</div>
                          <div class="card-detail-desc font-weight-normal-bold">**********7504</div>
                        </div>
                      </div>

                      <div class="row mt-3">
                        <div class="col-12">
                          <div class="card-detail-subtitle">Engine Number</div>
                          <div class="card-detail-desc font-weight-normal-bold">******504</div>
                        </div>
                      </div>

                      <div class="row mt-3">
                        <div class="col-12">
                          <div class="card-detail-subtitle">Car Location</div>
                          <div class="card-detail-desc font-weight-normal-bold">Peninsular Malaysia</div>
                        </div>
                      </div>

                      <div class="row mt-3">
                        <div class="col-12">
                          <div class="card-detail-subtitle">Vehicle Postcode</div>
                          <div class="card-detail-desc font-weight-normal-bold">47500</div>
                        </div>
                      </div>

                      <div class="row mt-3">
                        <div class="col-12">
                          <div class="card-detail-subtitle">No Claim Discount (NCD)</div>
                          <div class="card-detail-desc font-weight-normal-bold">45.00%</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card shadow py-4">
                  <div class="card-header" id="headingTwo">
                    <div class="row">
                      <div class="col-2 my-auto">
                        <i class="fa fa-male card-icon" aria-hidden="true"></i>
                      </div>
                      <div class="col-8">
                        <div class="card-detail-title">Personal Details</div>
                        <a href="#" class="edit-link">Edit</a>
                      </div>
                      <div class="col-2 my-auto">
                        <i class="fa fa-angle-down arrow-icon" v-bind:class="{ rotate: is_personal_icon_rotate }" v-on:click="rotate('personal')" aria-hidden="true" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo"></i>
                      </div>
                    </div>
                    <!--<h5 class="mb-0">
                      <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        Collapsible Group Item #1
                      </button>
                    </h5>-->
                    <div class="row mt-3">
                      <div class="col-12">
                        <div class="card-detail-subtitle">Name</div>
                        <div class="card-detail-desc font-weight-normal-bold">YEW Y***</div>
                      </div>
                    </div>
                  </div>
                  <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-12">
                          <div class="card-detail-subtitle">NRIC Number</div>
                          <div class="card-detail-desc font-weight-normal-bold">950302****53</div>
                        </div>
                      </div>

                      <div class="row mt-3">
                        <div class="col-12">
                          <div class="card-detail-subtitle">Date of Birth</div>
                          <div class="card-detail-desc font-weight-normal-bold">3 March 1995</div>
                        </div>
                      </div>

                      <div class="row mt-3">
                        <div class="col-12">
                          <div class="card-detail-subtitle">Gender</div>
                          <div class="card-detail-desc font-weight-normal-bold">Male</div>
                        </div>
                      </div>

                      <div class="row mt-3">
                        <div class="col-12">
                          <div class="card-detail-subtitle">Marital Status</div>
                          <div class="card-detail-desc font-weight-normal-bold">Single</div>
                        </div>
                      </div>

                      <div class="row mt-3">
                        <div class="col-12">
                          <div class="card-detail-subtitle">Mobile Number</div>
                          <div class="card-detail-desc font-weight-normal-bold">******1163</div>
                        </div>
                      </div>

                      <div class="row mt-3">
                        <div class="col-12">
                          <div class="card-detail-subtitle">Email</div>
                          <div class="card-detail-desc font-weight-normal-bold">ye***************@gmail.com</div>
                        </div>
                      </div>

                      <div class="row mt-3">
                        <div class="col-12">
                          <div class="card-detail-subtitle">Address</div>
                          <div class="card-detail-desc font-weight-normal-bold">example address Line</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-5">
            <div class="col-12">
              <div class="indicate-title">Additional Coverage</div>
              <p class="font-weight-normal">Complete your coverage benefits with the optional add-ons below</p>
            </div>
          </div>

          <!-- OTO360 Takaful -->
          <div class="card shadow">
            <div class="card-body">
              <div class="row">
                <div class="col-10">
                  <div class="card-detail-title">OTO360 Takaful</div>
                </div>
                <div class="col-2 my-auto">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" :value="true" v-model="pa.is_selected" id="add-takaful">
                    <label class="form-check-label" for="add-takaful">
                      Add
                    </label>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-12">
                  <div class="card-detail-subtitle">Get protection for driver and passenger with 24-hour Etiqa Auto Assist service and free unlimited towing mileage.</div>
                </div>
              </div>
              <div class="row mt-3" v-if="pa.is_selected">
                <div class="col-12">
                  <div class="btn-group btn-group-toggle btn-main-toggle" data-toggle="buttons">
                    <label class="btn btn-outline-primary active">
                      <input type="radio" v-model="pa.type" name="pa" :value="1" checked> Sliver
                    </label>
                    <label class="btn btn-outline-primary">
                      <input type="radio" v-model="pa.type" name="pa" :value="2"> Gold
                    </label>
                    <label class="btn btn-outline-primary">
                      <input type="radio" v-model="pa.type" name="pa" :value="3"> Platinum
                    </label>
                  </div>
                </div>

              </div>
              <div class="row mt-3" v-if="pa.is_selected">
                <div class="col-12">
                  <div class="card-detail-subtitle">Sum Covered <span class="font-weight-normal">RM10,000</span></div>
                </div>
              </div>
            </div>
          </div>

          <!-- Windscreen Coverage -->
          <div class="card mt-4 shadow">
            <div class="card-body">
              <div class="row">
                <div class="col-10">
                  <div class="card-detail-title">Windscreen Coverage</div>
                </div>
                <div class="col-2 my-auto">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="windscreen-coverage" v-model="windscreen.is_selected">
                    <label class="form-check-label" for="windscreen-coverage">
                      Add
                    </label>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-12">
                  <div class="card-detail-subtitle">Get covered for windscreen damages without affecting your NCD.</div>
                </div>
              </div>
              <div class="row mt-3" v-if="windscreen.is_selected">
                <div class="col-12">
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text">RM</span>
                    </div>
                    <input type="text" placeholder="e.g. 500" class="form-control form-control-lg" v-model="windscreen.amount" id="windscreen-coverage-input">
                  </div>
                </div>
              </div>
              <div class="row" v-if="windscreen.is_selected">
                <div class="col-12">
                  <div class="card-detail-subtitle">Minimum sum covered is RM400</div>
                </div>
              </div>
            </div>
          </div>

          <!-- Extreme Weather Coverage -->
          <div class="card mt-4 shadow">
            <div class="card-body">
              <div class="row">
                <div class="col-10">
                  <div class="card-detail-title">Extreme Weather Coverage</div>
                </div>
                <div class="col-2 my-auto">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="weather-coverage" v-model="weather.is_selected">
                    <label class="form-check-label" for="weather-coverage">
                      Add
                    </label>
                  </div>
                </div>
              </div>
              <div class="row mt-3" v-if="weather.is_selected">
                <div class="col-12">
                  <div class="btn-group btn-group-toggle btn-main-toggle" data-toggle="buttons">
                    <label class="btn btn-outline-primary active">
                      <input type="radio" v-model="weather.selected_code" name="weather" :value="weather.basic_code" checked> Basic Flood
                    </label>
                    <label class="btn btn-outline-primary">
                      <input type="radio" v-model="weather.selected_code" name="weather" :value="weather.extend_code"> Extended Flood Coverage
                    </label>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-12">
                  <div class="card-detail-subtitle">Get covered for damages caused by floods, thunderstorms, and landslides.</div>
                </div>
              </div>
            </div>
          </div>

          <!-- Protection Third Party -->
          <div class="card mt-4 shadow">
            <div class="card-body">
              <div class="row">
                <div class="col-10">
                  <div class="card-detail-title">
                    Protection for Third Party</div>
                </div>
                <div class="col-2 my-auto">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="protection-third-party" v-model="protection_third_party.is_selected">
                    <label class="form-check-label" for="protection-third-party">
                      Add
                    </label>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-12">
                  <div class="card-detail-subtitle">Drive with complete protection for legal liability in case of accidents.</div>
                </div>
              </div>
              <div class="row mt-3" v-if="protection_third_party.is_selected">
                <div class="col-12">
                  <div class="form-check">
                    <input class="form-check-input" name="legal-liability-passenger" type="checkbox" id="legal-liability-passenger-one" v-model="legal_liablity.is_selected">
                    <label class="form-check-label" for="legal-liability-passenger-one">
                      Legal Liability of Passengers
                    </label>
                    <p for="legal-liability-passenger-one">Get protection for your frequent drive to Singapore. It provides you legal liability coverage for injuries affecting a 3rd party due to negligence acts committed by passengers.</p>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" name="legal-liability-passenger" type="checkbox" id="legal-liability-passenger-two" v-model="legal_liability_act.is_selected">
                    <label class="form-check-label" for="legal-liability-passenger-two">
                      Legal Liability of Passengers
                    </label>
                    <p for="legal-liability-passenger-two">Get protection against acts of negligence by your passenger that causes loss or damage to a third party.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Cash Assistance -->
          <div class="card mt-4 shadow">
            <div class="card-body">
              <div class="row">
                <div class="col-10">
                  <div class="card-detail-title">
                    Cash Assistance</div>
                </div>
                <div class="col-2 my-auto">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="cash-assistance">
                    <label class="form-check-label" for="cash-assistance">
                      Add
                    </label>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-12 mb-2">
                  <a href="#" class="btn btn-outline-primary active" role="button" aria-pressed="true">Compensation when your vehicle is under repair (CART)</a>
                </div>
                <div class="col-12">
                  <a href="#" class="btn btn-outline-primary" role="button" aria-pressed="true">Loss of use (LOU)</a>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-12">
                  <div class="card-detail-subtitle">
                    Get compensation based on the duration that your vehicle is laid up in the workshop (up to 10 days).
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-12">
                  <div class="form-group">
                    <select class="form-control" id="days">
                      <option>10 days, RM50/day</option>
                      <option>10 days, RM100/day</option>
                      <option>10 days, RM150/day</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Cost of Replacement -->
          <div class="card mt-4 shadow">
            <div class="card-body">
              <div class="row">
                <div class="col-10">
                  <div class="card-detail-title">
                    Cost of Replacement</div>
                </div>
                <div class="col-2 my-auto">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="protection-third-party">
                    <label class="form-check-label" for="protection-third-party">
                      Add
                    </label>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-12">
                  <div class="form-check">
                    <input class="form-check-input" name="replacement" type="checkbox" id="replacement-one">
                    <label class="form-check-label" for="replacement-one">
                      Smart Key Replacement Coverage
                    </label>
                    <p for="legal-liability-passenger-one">Get covered up to RM3,000 to replace your lost, stolen or damaged car smart key.</p>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" name="replacement" type="checkbox" id="replacement-two">
                    <label class="form-check-label" for="replacement-two">
                      Child Car Safety Seat
                    </label>
                    <p for="legal-liability-passenger-two">Get covered for repairs or replacement up top RM500 by providing police report and a copy of the repair invoice/receipt</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Maintain Like New Coverage -->
          <div class="card mt-4 shadow">
            <div class="card-body">
              <div class="row">
                <div class="col-10">
                  <div class="card-detail-title">
                    Maintain Like New Coverage</div>
                </div>
                <div class="col-2 my-auto">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="maintain-coverage">
                    <label class="form-check-label" for="maintain-coverage">
                      Add
                    </label>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-12">
                  <div class="form-check">
                    <input class="form-check-input" name="replacement" type="checkbox" id="maintain-one">
                    <label class="form-check-label" for="maintain-one">
                      New Spare Part Replacement Cover
                    </label>
                    <p for="legal-liability-passenger-one">Replace accident-damaged parts at no additional charges.<br>*Applicable for vehicles above 5 years old.</p>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" name="replacement" type="checkbox" id="maintain-two">
                    <label class="form-check-label" for="maintain-two">
                      Car Respray
                    </label>
                    <p for="legal-liability-passenger-two">Get the whole car repainted, not just the damaged area <br> *Applicable for cars below 10 years old</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-5">
            <div class="col-12">
              <button type="button" class="btn btn-outline-primary btn-lg btn-radius" style="margin-right: 25px"><i class="fa fa-arrow-left" aria-hidden="true"></i> Back</button>
              <button type="button" class="btn btn-primary btn-lg btn-radius">To Declaration Page <i class="fa fa-arrow-right" aria-hidden="true"></i></button>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-12 col-xl-6 col-lg-6 order-0 order-md-0 order-xl-1 order-lg-1 sticky-top">
          <div class="sticky-top" style="top: 10%">
            <div class="card card-top-section" style="background-color: #F6AC59">
              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <div class="indicate-title mb-3">Quotation Details</div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12">
                  <!--<div class="row mb-3">
                      <div class="col-6 mt-auto">
                        <div class="total">Total Motor Quotation:</div>
                      </div>
                      <div class="col-6 text-right">
                        <div class="total-value">RM{{ subtotal_motor }}</div>
                      </div>
                    </div>-->

                    <!-- Total Payable -->
                    <div class="row mb-3">
                      <div class="col-6 mt-auto">
                        <div class="total">Total Payable:</div>
                      </div>
                      <div class="col-6 text-right">
                        <div class="total-value">RM{{ total_payable | toDecimal }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card card-bottom-section">
              <div class="card-body">
                <!-- Insure -->
                <div class="row">
                  <div class="col-6">
                    <div class="insured-form font-weight-lighter">Insured From</div>
                  </div>
                  <div class="col-6 text-right">
                    <div class="insured-form font-weight-lighter">Insured Till</div>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-12">
                    <div class="card inner-card">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-6">
                            {{ quote.coverageStartDate }}
                          </div>
                          <div class="col-6 text-right">
                            {{ quote.coverageEndDate }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- END of Insure -->

                <div class="row mt-3">
                  <div class="col-6">
                    <div class="sum-insured font-weight-normal-bold">Sum Insured</div>
                  </div>
                  <div class="col-6 text-right">
                    <div class="sum-insured font-weight-normal-bold">RM{{ sum_covered_range | toDecimal }}</div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-6">
                    <div class="basic-premium font-weight-lighter">Basic Premium</div>
                  </div>
                  <div class="col-6 text-right">
                    <div class="basic-premium font-weight-lighter">RM{{ quote.basicPremium }}</div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-8">
                    <div class="basic-premium font-weight-lighter">No Claim Discount (NCD) <span class="discount">{{ quote.ncdPercentage }}%</span></div>
                  </div>
                  <div class="col-4 text-right">
                    <div class="basic-premium font-weight-lighter"><span class="discount">-RM{{ quote.ncdAmount }}</span></div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-6">
                    <div class="additional-coverage-title font-weight-normal-bold">Additional Coverage:</div>
                  </div>
                  <div class="col-6 text-right"></div>
                </div>

                <div class="row">
                  <div class="col-6">
                    <div class="additional-coverage font-weight-lighter">Windscreen Coverage</div>
                  </div>
                  <div class="col-6 text-right">
                    <div class="additional-coverage font-weight-lighter">RM75.00</div>
                  </div>
                </div>
                <hr>

                <div class="row mb-4">
                  <div class="col-6">
                    <div class="gross-contribution font-weight-normal-bold">Gross Premium</div>
                  </div>
                  <div class="col-6 text-right font-weight-normal-bold">RM{{ quote.grossPremium }}</div>
                </div>

                <div class="row">
                  <div class="col-6">
                    <div class="discount-title font-weight-lighter">Discount (10%)</div>
                  </div>
                  <div class="col-6 text-right font-weight-lighter"><span class="discount">-RM{{ quote.totalDiscount }}</span></div>
                </div>

                <div class="row">
                  <div class="col-6">
                    <div class="service-tax-title font-weight-lighter">Service Tax (6%)</div>
                  </div>
                  <div class="col-6 text-right font-weight-lighter">RM{{ quote.taxAmount }}</div>
                </div>

                <div class="row">
                  <div class="col-6">
                    <div class="service-tax-title font-weight-lighter">Stamp Duty</div>
                  </div>
                  <div class="col-6 text-right font-weight-lighter">RM10.00</div>
                </div>
                <hr>

                <div class="row">
                  <div class="col-8">
                    <div class="total-motor-contribution font-weight-normal-bold">Total Motor Premium</div>
                  </div>
                  <div class="col-4 text-right font-weight-normal-bold">RM{{ quote.nettPremium }}</div>
                </div>

                <hr>

                <div class="row" v-if="pa.is_selected">
                  <div class="col-12">

                    <div class="row">
                      <div class="col-8">
                        <div class="total-motor-contribution font-weight-normal-bold">Gross Contribution OTO360</div>
                      </div>
                      <div class="col-4 text-right font-weight-normal-bold">RM{{ pa_info.grossPremium }}</div>
                    </div>

                    <div class="row">
                      <div class="col-6">
                        <div class="service-tax-title font-weight-lighter"><span class="discount">Discount(10%)</span></div>
                      </div>
                      <div class="col-6 text-right font-weight-lighter"><span class="discount">-RM{{ pa_info.discount }}</span></div>
                    </div>

                    <div class="row">
                      <div class="col-8">
                        <div class="service-tax-title font-weight-lighter">Contribution After Discount</div>
                      </div>
                      <div class="col-4 text-right font-weight-lighter">RM{{ pa_info.premiumAfterDiscount }}</div>
                    </div>

                    <div class="row">
                      <div class="col-6">
                        <div class="service-tax-title font-weight-lighter">OTO Assist Fee</div>
                      </div>
                      <div class="col-6 text-right font-weight-lighter">RM{{ pa_info.fee }}</div>
                    </div>

                    <div class="row">
                      <div class="col-6">
                        <div class="service-tax-title font-weight-lighter">Service Tax(6%)</div>
                      </div>
                      <div class="col-6 text-right font-weight-lighter">RM{{ pa_info.tax }}</div>
                    </div>

                    <div class="row">
                      <div class="col-6">
                        <div class="service-tax-title font-weight-lighter">Stamp Duty(PA)</div>
                      </div>
                      <div class="col-6 text-right font-weight-lighter">RM10.00</div>
                    </div>
                  </div>
                </div>
                <hr v-if="pa.is_selected">
                <div class="row" v-if="pa.is_selected">
                  <div class="col-12">
                    <div class="row">
                      <div class="col-8">
                        <div class="total-motor-contribution font-weight-normal-bold">Total OTO360</div>
                      </div>
                      <div class="col-4 text-right font-weight-normal-bold">RM{{ pa_info.premium }}</div>
                    </div>
                  </div>
                </div>
                <hr v-if="pa.is_selected">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="space"></div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Quote",
  data () {
    return {
      quote: '',
      is_car_icon_rotate: false,
      is_personal_icon_rotate: false,
      sum_covered_range: 0,
      subtotal_motor: '',
      agreedMin: '',
      agreedMax: '',


      pa: {
        is_selected: false,
        type: 1
      },
      windscreen: {
        is_selected: false,
        code: 'B089',
        amount: 500
      },
      weather: {
        is_selected: false,
        basic_code: 'C57A',
        extend_code: 'B057',
        selected_code: 'C57A'
      },
      protection_third_party: {
        is_selected: false,
      },
      legal_liablity: {
        is_selected: false,
        code: 'B072'
      },
      legal_liability_act: {
        is_selected: false,
        code: 'B100'
      },



      pa_info: '',
      total_payable: 0,

      additional_benefits: {
        sumInsured: '',
        code: [],
        codeWithSumInsured: [],
        sumInsuredAdditionalBenefit: []
      }
    }
  },
  watch: {
    protection_third_party: {
      handler (object) {
        if (object.is_selected === false) {
          this.legal_liablity.is_selected = false
          this.legal_liability_act.is_selected = false
        }
      },
      deep: true
    },
    legal_liablity: {
      handler (object) {
        if (object.is_selected === true) {
          this.additional_benefits.code.push(object.code);
        }
        else {
          this.additional_benefits.code.forEach((code, index) => {
            if (code === this.legal_liablity.code)
            {
              this.additional_benefits.code.splice(index, 1);
            }
          });
        }
        this.checkBenefit();
      },
      deep: true
    },
    legal_liability_act: {
      handler (object) {
        if (object.is_selected === true) {
          this.additional_benefits.code.push(object.code);
        }
        else {
          this.additional_benefits.code.forEach((code, index) => {
            if (code === this.legal_liability_act.code)
            {
              this.additional_benefits.code.splice(index, 1);
            }
          });
        }
        this.checkBenefit();
      },
      deep: true
    },
    weather: {
      handler (object) {
        if (object.is_selected === true) {
          this.additional_benefits.code.push(object.selected_code);
        }
        else {
          this.additional_benefits.code.forEach((code, index) => {
            if (code === this.weather.selected_code)
            {
              this.additional_benefits.code.splice(index, 1);
            }
          });
        }

        this.checkBenefit();
      },
      deep: true
    },
    windscreen: {

      handler (object) {

        if (object.is_selected === true) {
          this.additional_benefits.codeWithSumInsured.push(object.code)
          this.additional_benefits.sumInsuredAdditionalBenefit.push(object.amount)
        }
        else {
          this.additional_benefits.codeWithSumInsured.forEach((code, index) => {
            if (code === this.windscreen.code)
            {
              this.additional_benefits.codeWithSumInsured.splice(index, 1);
              this.additional_benefits.sumInsuredAdditionalBenefit.splice(index, 1);
            }
          });
        }

        this.checkBenefit();
      },
      deep: true
    },
    'pa.type' () {
      axios.post('http://localhost:3000/api/get/personal/accident/additional', {
        qqId: this.quote.qqId,
        type: this.pa.type
      })
          .then((response) => {

            this.pa_info = response.data.pa;

            this.quote.nettPremium = response.data.pa.nettPremium;

            this.total_payable = this.$options.filters.toCurrencyDouble(this.quote.nettPremium) + this.$options.filters.toCurrencyDouble(this.pa_info.premium)
          })
          .catch((error) => {
            console.log(error);
          });
    },
    'pa.is_selected' (newValue) {

      if (newValue === true)
      {
        axios.post('http://localhost:3000/api/get/personal/accident/additional', {
          qqId: this.quote.qqId,
          type: this.pa.type
        })
            .then((response) => {

              this.pa_info = response.data.pa;

              this.quote.nettPremium = response.data.pa.nettPremium;

              this.total_payable = this.$options.filters.toCurrencyDouble(this.quote.nettPremium) + this.$options.filters.toCurrencyDouble(this.pa_info.premium)

            })
            .catch((error) => {
              console.log(error);
            });
      }
      else {
        this.pa_info = '';
        this.total_payable = this.$options.filters.toCurrencyDouble(this.quote.nettPremium);
      }
    },
    sum_covered_range (newValue, oldValue) {

      console.log(oldValue);
      if (oldValue > 0)
      {
        axios.post('http://localhost:3000/api/get/sumInsured', {
          sumInsured: newValue,
          qqId: this.quote.qqId
        })
            .then((response) => {

              console.log(response.data)

              this.quote.nettPremium = response.data.insured_quote.nettPremium;
              this.quote.grossPremium = response.data.insured_quote.grossPremium;
              this.quote.taxAmount = response.data.insured_quote.taxAmount;
              this.quote.totalDiscount = response.data.insured_quote.totalDiscount;
              this.quote.ncdAmount = response.data.insured_quote.ncdAmount;
              this.quote.basicPremium = response.data.insured_quote.basicPremium;

              this.total_payable = this.$options.filters.toCurrencyDouble(this.quote.nettPremium);

              console.log(this.total_payable);
            })
            .catch((error) => {
              console.log(error);
            });
      }
    }
  },
  mounted() {
    console.log(this.moment().format('dd/mm/yyyy'));
    if (sessionStorage.getItem('quoteData'))
    {
      this.quote = JSON.parse(sessionStorage.getItem('quoteData'));

      this.sum_covered_range = this.$options.filters.toNumber(this.$options.filters.removeComma(this.quote.sumInsured));

      this.total_payable = this.$options.filters.toCurrencyDouble(this.quote.nettPremium);

      if (this.quote.agreedValueRange) {
        let splitValue = this.quote.agreedValueRange.split(' - ');

        this.agreedMin = this.$options.filters.toDecimal(this.$options.filters.toCurrencyDouble(splitValue[0]))
        this.agreedMax = this.$options.filters.toDecimal(this.$options.filters.toCurrencyDouble(splitValue[1]))
      }
    }
    console.log(this.quote);
  },
  methods: {
    rotate (type) {
      if (type === 'car' && this.is_car_icon_rotate === false) {
        this.is_car_icon_rotate = true;
      }
      else {
        this.is_car_icon_rotate = false;
      }

      if (type === 'personal') {
        this.is_personal_icon_rotate = true;
      }
      else {
        this.is_personal_icon_rotate = false;
      }
    },
    checkBenefit()
    {
      axios.post('http://localhost:3000/api/get/additional/benefit', {
        qqId: this.quote.qqId,
        sumInsured: this.sum_covered_range,
        code: this.additional_benefits.code,
        codeWithSumInsured: this.additional_benefits.codeWithSumInsured,
        sumInsuredAdditionalBenefit: this.additional_benefits.sumInsuredAdditionalBenefit
      })
          .then((response) => {

            console.log(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
    }
  },
  filters: {
    toNumber (number) {
      return parseInt(number);
    },
    toDecimal (value) {
      let val = (value/1).toFixed(2).replace(',', '.')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    removeComma(string){
      let displayValue = string || ""; //check if this.value is null
      displayValue = String(displayValue).replace(/,/g, ""); //replace ,

      return displayValue;
    },
    toCurrencyDouble (string) {
      return Number(string.replace(/[^0-9.-]+/g,""));
    }
  },

}
</script>

<style scoped>

</style>
