import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import jQuery from 'jquery';
import 'popper.js';
import 'bootstrap';
import './assets/scss/app.scss';
import Datepicker from 'vuejs-datepicker';
import Ls from './services/ls'
import AxiosPlugin from 'vue-axios-cors';
import Vuelidate from 'vuelidate';
import moment from 'moment'

import Vue2Filters from 'vue2-filters'

Vue.use(Vue2Filters)

Vue.use(AxiosPlugin)

Vue.use(Vuelidate)

Vue.config.productionTip = false



Vue.prototype.$hostname = 'https://panel.dnmadvisory.com'

Vue.component('datepicker', Datepicker);

Vue.use({
  install: function(Vue){
    Vue.prototype.$ = jQuery;
    Vue.prototype.moment = moment
  }
});

window.axios = require('axios')
window.Ls = Ls

window.axios.interceptors.request.use(function (config) {
  // Do something before request is sent
  const AUTH_TOKEN = Ls.get('auth.token')

  if (AUTH_TOKEN) {
    config.headers.common['Authorization'] = `Bearer ${AUTH_TOKEN}`
  }

  config.headers.common["Access-Control-Allow-Credentials"] = "true"
  config.headers.common["Access-Control-Allow-Origin"] = "http://localhost:8080"
  config.headers.common["Access-Control-Allow-Methods"] = "GET, PUT, POST, DELETE, HEAD, OPTIONS, PATCH, PROPFIND, PROPPATCH, MKCOL, COPY, MOVE, LOCK"
  config.headers.common["Access-Control-Allow-Headers"] = "*"
  config.headers.common["Access-Control-Expose-Headers"] = "*"

  return config
}, function (error) {
  // Do something with request error
  return Promise.reject(error)
})



Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
