<template>
  <div class="container">
    <div class="row mt-5 justify-content-center">
      <div class="col-md-5">
        <input type="text" v-model="code" class="form-control" placeholder="OAuth Self Client Code">
        <button class="btn btn-primary btn-block mt-5" @click="grantAuthorizationCode" :disabled="loading || code == null || (code && code.length === 0)">
          Grant Authorization Code
        </button>

        <div class="alert alert-success alert-dismissible fade show mt-5" role="alert" v-if="isSuccess">
          <strong>Authenticated!</strong> Your app is connect with Zoho CRM
          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'OAuthGrant',
    data () {
      return {
        code: null,
        loading: false,
        isSuccess: false
      }
    },
    methods: {
      grantAuthorizationCode () {
        this.loading = true
        window.axios.post('/api/authorize-oauth-grant', {code: this.code})
            .then((res) => {
              if (res.data.success) {
                this.isSuccess = true
              }
            })
            .finally(() => {
              this.loading = false
            })
      }
    }
}
</script>

<style scoped>

</style>
